<template>
  <section id="surgery-iformation">
    <ItemHeader title="Dados da cirurgia" class="mt-3" v-if="showSurgeryData">
      <template v-slot:content>
        <SurgeryData
          :procedures="computedSurgeryProcedures"
          :surgeryInformationId="surgeryInformation?.id"
          @update="updateSurgeryProcedures"
        />
      </template>
    </ItemHeader>

    <ItemHeader title="Arquivos da cirurgia">
      <template v-slot:header>
        <b-button variant="link" class="ml-auto" @click="openDocumentModal">
          <span class="icon">
            <Clip />
          </span>
          Anexar arquivo
        </b-button>
      </template>

      <template v-slot:content>
        <Files
          :patientId="patientId"
          :surgeryInformationId="surgeryInformation?.id"
          :needReloadFiles="needReloadFiles"
          :reloadFiles="reloadFiles"
        />
      </template>
    </ItemHeader>

    <ItemHeader title="Informações do paciente">
      <template v-slot:content>
        <PatientInformation :patientId="patientId" />
      </template>
    </ItemHeader>

    <ItemHeader title="Comentários">
      <template v-slot:content>
        <Comments
          :patientId="patientId"
          :surgeryInformationId="surgeryInformation?.id"
          :showSaveButton="showSaveButton"
          @update-comments="updateComments"
        />
      </template>
    </ItemHeader>

    <div class="d-flex justify-content-end p-4" v-if="showSaveButton">
      <b-button variant="primary" @click="saveSurgeryInformation">
        Salvar informações
      </b-button>
    </div>

    <SurgeryFilesModal
      :patientId="patientId"
      :surgeryInformationId="surgeryInformation?.id"
      @reload-files="reloadFiles"
    />
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'SurgeryInformation',
  props: {
    patientId: {
      type: String,
      required: true
    },
    appointmentId: {
      type: String,
      required: true
    },
    surgeryProcedures: {
      type: Array,
      default: () => []
    },
    showSurgeryData: {
      type: Boolean,
      default: true
    },
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ItemHeader: () =>
      import('@/components/SurgeryCenter/SurgeryInformation/ItemHeader'),
    SurgeryData: () =>
      import('@/layouts/SurgeryCenter/SurgeryInformation/SurgeryData'),
    PatientInformation: () =>
      import('@/layouts/SurgeryCenter/SurgeryInformation/PatientInformation'),
    Files: () => import('@/layouts/SurgeryCenter/SurgeryInformation/Files'),
    Comments: () =>
      import('@/layouts/SurgeryCenter/SurgeryInformation/Comments'),
    SurgeryFilesModal: () =>
      import(
        '@/components/SurgeryCenter/SurgeryInformation/Modal/SurgeryFilesModal'
      ),
    Clip: () => import('@/assets/icons/clip.svg')
  },
  data: () => ({
    clinic: getCurrentClinic(),
    surgeryInformation: {},
    needReloadFiles: false,
    surgeryProceduresToSave: []
  }),
  computed: {
    computedSurgeryProcedures() {
      return this.surgeryProcedures.map(procedure => {
        if (procedure?.participants?.length) {
          return procedure
        }
        return {
          ...procedure,
          participants: []
        }
      })
    }
  },
  async created() {
    await this.getSurgeryInformationByAppointment()
    if (!this.surgeryInformation) {
      await this.createSurgeryInformation()
    }
  },
  methods: {
    openDocumentModal() {
      this.$bvModal.show('surgery-files-modal')
    },
    reloadFiles(value) {
      this.needReloadFiles = value
    },
    updateSurgeryProcedures(procedures) {
      this.surgeryProceduresToSave = procedures
    },
    async createSurgeryInformation() {
      try {
        const { data } = await this.api.createSurgeryInformation({
          appointment_id: this.appointmentId,
          clinic_id: this.clinic.id
        })
        this.surgeryInformation = data
      } catch (error) {
        this.$toast.error('Erro ao criar informações da cirurgia')
      }
    },
    async getSurgeryInformationByAppointment() {
      try {
        const { data } = await this.api.getSurgeryInformationByAppointment(
          this.appointmentId
        )
        this.surgeryInformation = data
        this.$emit('get-surgery-information', data)
      } catch (error) {
        this.$toast.error('Erro ao carregar informações da cirurgia')
      }
    },
    updateComments(comments) {
      comments.forEach(comment => delete comment.professioanl)
      this.$set(this.surgeryInformation, 'comments', comments)
    },
    async saveSurgeryInformation() {
      // if (this.isInvalidParticipants()) {
      //   this.$toast.warning(
      //     'Preencha todas informações dos participantes antes de salvar'
      //   )
      //   return
      // }
      const isLoadding = this.$loading.show()
      await this.saveProcedures()
      await this.saveComments()
      isLoadding.hide()
      this.$toast.success('Informações salvas com sucesso')
    },
    async saveProcedures() {
      try {
        const { data } = await this.api.saveSurgeryInformationProcedure({
          surgery_information_id: this.surgeryInformation.id,
          procedures: this.surgeryProceduresToSave
        })
        this.surgeryProceduresToSave = []
        this.$emit('updateSurgeryProcedures', data)
      } catch (error) {
        this.$toast.error('Erro ao salvar procedimentos')
      }
    },
    async saveComments() {
      try {
        if (!this.surgeryInformation.comments?.length) {
          return
        }
        await this.api.saveSurgeryInformationComment({
          surgery_information_id: this.surgeryInformation.id,
          comments: this.surgeryInformation.comments,
          clinic_id: this.clinic.id
        })
      } catch (error) {
        this.$toast.error('Erro ao salvar comentários')
      }
    },
    isInvalidParticipants() {
      const participants = this.surgeryProceduresToSave.map(
        procedure => procedure.participants
      )
      const participantsInvalids = participants.map(participant => {
        return participant.some(p => {
          return !p.type || !p.beneficiary_id
        })
      })
      return participantsInvalids.some(p => p)
    }
  }
}
</script>

<style lang="scss" scoped>
#surgery-information {
  .icon {
    svg {
      width: 14px !important;
      height: 14px !important;
      margin-right: 5px;
    }
  }
}
</style>
